<template>
  <error-page v-if="errorCode" />
  <router-view v-else />
</template>

<script>
import { mapState } from 'vuex'
import ErrorPage from '../pages/ErrorPage.vue'
export default {
  components: { ErrorPage },
  computed: {
    ...mapState(['errorCode'])
  }
}
</script>